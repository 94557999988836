<template>
	<div id="nav">
	  <router-link to="/"><span @click="homeClicked()">Home</span></router-link>
	  <router-link to="/about">About</router-link>
	  <router-link v-if="checkOwner()" to="/owner">Owner's Page</router-link>
	</div>
</template>
<script>
var methods = {}
import {ethers} from "ethers";

methods.checkOwner = function () {
	console.log('check owner')
	console.log(this.$store.state)
	if (this.$store.state.currentAccount && this.$store.state.owner) {
		var current = ethers.utils.getAddress(this.$store.state.currentAccount)
		var owner = ethers.utils.getAddress(this.$store.state.owner)
		console.log(current)
		console.log(owner)
		if ( current === owner) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}

methods.homeClicked = function () {
	console.log('homeClicked')
	this.$store.dispatch('loadEverything')
}

export default {
  name: 'Navbar',
  data: function () {
    return {

    }
  },
  components: {
    // VueMetamask
  },
  methods: methods,
  mounted: function () {

    // this.checkIsConnected()
  }
}
</script>
<style lang="scss" scoped>
#nav {
  padding: 30px;
  float:left;
  width:100%;
  position:relative;

  a {
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding: 15px;

    &.router-link-exact-active {
      text-decoration: underline;
    }
  }
}
#routerView {
  position:relative;
  width:100%;
  float:left;
}
</style>