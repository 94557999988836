import Vue from 'vue'
import Vuex from 'vuex'
const BN = require('bn.js');
import {ethers} from "ethers";
import Web3 from "web3";
const axios = require('axios');
import contract from './../contracts/AYY_CEE.json'
const abi = contract.abi;
// const contractAddress = '0xB7E3Bc7013858C16D567cdC9b4fA7a26022F9D2e'
// const contractAddress = "0x32D5941C58b7AE8570A5D09aC555B03A9C487E20"
// const contractAddress = "0xC0EeA019bd5E76a7dA9438142cB6eB34b11fbD5e"
// const contractAddress = "0x8ceFB3e87390834cCDBDC598873C67C14DB90fEd"
const contractAddress = "0x95E0B57b7B776DF013D5FFAA9B51b1496aB5644c"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	contract: null,
  	currentAccount: null,
  	loadingEverything: true,
  	signer: null,
  	contractError: false,
  	contractStatus: null,
		owner: null,
		totalSupply: null,
		maxSupply: null,
		maxMint: null,
		connectLoading: false,
  },
  mutations: {
  	setCurrentAccount(state, account) {
  		state.currentAccount = account
  	},
  	setLoading(state, val) {
  		state.loadingEverything = val
  	},
  	setSigner(state, signer) {
  		state.signer = signer
  	},
  	setContractError(state, val) {
  		state.contractError = val
  	},
  	setContractStatus (state, val) {
  		state.contractStatus = val
  	},
  	setContract (state, val) {
  		state.contract = val
  	},
		setOwner (state, val) {
			state.owner = val
		},
		setTotalSupply (state, val) {
			state.totalSupply = val
		},
		setMaxSupply (state, val) {
			state.maxSupply = val
		},
		setMaxMint (state, val) {
			state.maxMint = val
		},
		setConnectLoading (state, val) {
			state.connectLoading = val
		},
  },
  actions: {
  	async checkWallet ({commit, dispatch}) {
  		console.log('checking wallet from store')
  		console.log(dispatch)
  		var that = this
  		window.ethereum.on('accountsChanged', function (accounts) {
	      console.log('accountsChanges',accounts);
	      dispatch('checkIsConnected');
	      dispatch('loadEverything');
	    });

	     // detect Network account change
	    window.ethereum.on('chainChanged', function(networkId){
	      console.log('chainChanged',networkId);
	      dispatch('checkIsConnected');
	      dispatch('loadEverything');
	    });
  	},
  	async checkIsConnected ({commit, dispatch}) {
  		console.log('store check is connected')
  		const {ethereum} = window;

			if (!ethereum) {
				console.log('Make sure you connect MetaMask')
				return;
			} else {
				console.log('Wallet Exists ready to go!')
			}

			var accounts = await ethereum.request({method: 'eth_accounts'})
			console.log('accounts')
			if (accounts.length !== 0) {
				commit('setCurrentAccount', accounts[0])
			} else {
				console.log('no account found')
			}
  	},
  	async connectWallet({commit, dispatch}) {
  		commit('setConnectLoading', true)
			const {ethereum} = window
			if (!ethereum) {
				// this.connectLoading = false
				commit('setLoading', false)
				alert("Please Install Metamask")
			}

			try {
				const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
				// this.currentAccount = accounts[0]
				commit('setCurrentAccount', accounts[0])
				// setCurrentAccount(this.currentAccount)
				dispatch('loadEverything').then(res => {
					commit('setConnectLoading', false)
				})
			} catch (err) {
				commit('setConnectLoading', false)
			}
  	},
  	async loadEverything ({commit, dispatch}) {
  		console.log('loading everything from store')
  		commit('setLoading', true)
  		dispatch('getSigner').then(signer => {
  			commit('setSigner', signer)

  			dispatch('getContract', signer).then(contract => {
  				console.log('calling get contract')
  				if (!contract) {
  					commit('setContractError', true)
  				} else {
  					commit('setContractError', false)
  					console.log('got contract')
  					dispatch('configureTotals', contract).then(res => {
  						commit('setLoading', false)
  					})
  				}
  			});
  		});
  	},
  	async getContract({commit, dispatch}, signer) {
  		try {
				var contract = new ethers.Contract(contractAddress, abi, signer)
				commit('setContract', contract)
				return contract
			} catch {
				console.log('contract error')
				console.log(err)
				commit('setContractError', true)
				commit('setLoading', false)
				return false
			}
  	},
  	async getSigner ({commit, dispatch}) {
  		const {ethereum} = window
			try {
				var provider = new ethers.providers.Web3Provider(ethereum)
				// this.wallet = new ethers.signer.getAddress(ethereum)
				// this.wallet = new this.provider.getAddress()
				// console.log('wallet')
				// console.log(this.wallet)
				return provider.getSigner();
			} catch (e) {
				commit('setLoading', false)
				commit('setContractError', true)
			}
  	},
  	async configureTotals ({state, commit, dispatch}, contract) {
  		console.log('configure totals in store')
  		console.log(contract)
  		try {
  			console.log('configuring totals in store')
				const totalSupply = await contract.minted()
				const maxSupply = await contract.MAX_SUPPLY()
				const maxMint = await contract.MAX_PER_MINT()
				const contractStatus = await contract.live()
				const owner = await contract.owner()
				console.log('contract status')
				console.log(owner)
				commit('setContractStatus', contractStatus)
				commit('setOwner', owner)
				commit('setTotalSupply', totalSupply.toString())
				commit('setMaxSupply', maxSupply.toString())
				commit('setMaxMint', parseInt(maxMint.toString()))
				commit('setContractError', false)
				commit('setLoading', false)
				return
			} catch (err) {
				console.log('totals error')
				console.log(err)
				commit('setContractError', true)
				commit('setLoading', false)
				return
			}
  	}
  },
  modules: {
  }
})
