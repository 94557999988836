<template>
  <div class="home" style="float:left;width:100%;">
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="col-12 p-2 float-left" style="color:#fff;width:100%;float:left;">
		    <div class="col-12 text-center float-left">
		    	<button class="btn connectMetaButton" v-if="!$store.state.connectLoading && !$store.state.currentAccount" @click="connectWalletHandler()">
		    		Connect<br />
		    		<img style="width:150px;" src="./../assets/metamask_logo_2.svg" />
		    	</button>
		    	<b-spinner v-else-if="connectLoading"></b-spinner>
		    	<div class="col-12 float-left text-center" v-else-if="$store.state.currentAccount && !$store.state.contractError">
		    		<label id="walletLoaded">Wallet Loaded</label>
		    		<label id="contractStatus" :class="$store.state.contractStatus ? 'live' : 'notLive'">{{$store.state.contractStatus ? 'Contract Is Live' : 'Only presale is currently available'}}</label>
		    		<div id="opensea">
		    			<img id="openSeaImage" src="./../assets/opensea_logo.png" @click="goToOpensea()" style="width:30px;" />
		    		</div>
		    	</div>
		    </div>
		    <!-- <div class="col-12 text-center" v-if="connectMeta">
			    <vue-metamask 
			        userMessage="msg" 
			        @onComplete="onComplete"
			    >
			    </vue-metamask>
			  </div> -->
			  <div style="width:100%;float:left;" v-if="$store.state.contract && $store.state.currentAccount && !$store.state.contractError">
				  <div class="row mt-4">
				  	<div class="col-12 text-center">
				  		<label id="supply">{{$store.state.totalSupply}} / {{$store.state.maxSupply}}</label><br />
				  		Minted
				  	</div>
				  </div>
				  <div class="row mt-4" v-if="$store.state.contract && checkPresale() && presaleAccess">
				  	<div style="width:100%;float:left;" v-if="$store.state.contract">
					  	<div class="col-12 text-center mb-4">
					  		<input
					  			type="number"
					  			class="form-control"
					  			@change="mintChanged()"
					  			min="1"
					  			style="width:100px;margin:0 auto;"
					  			v-model="mint_number" />
					  		<label id="numToMint"># of Founding Fathers to Mint (max {{maxMint}})</label>
					  	</div>
					  	<div class="col-12 text-center" v-if="!mintLoading">
					  		<button class="btn btn-sm btn-success" v-if="$store.state.currentAccount && checkPresale()" @click="mintNftHandler()">Mint {{mint_number}} NFT(s) Preston little pussyyyy</button>
					  		<label v-else>Please Load Wallet First</label>

					  		<div id="discord">
				    			<img id="discordImage" src="./../assets/discord_logo.png" @click="goToDiscord()" />
				    		</div>
					  	</div>
					  	<div class='col-12 text-center' v-else-if="!minting">
					  		<b-spinner></b-spinner><br />
					  		<label>Loading your transaction, please don't close...</label>
					  	</div>
					  	<div class='col-12 text-center' v-else-if="minting">
					  		<b-spinner></b-spinner><br />
					  		<label>We are minting your NFT(s), please don't close!!!</label>
					  	</div>
					  </div>
					  <div v-else class="row text-center">
					  	<b-spinner></b-spinner>
					  </div>
				  </div>
				  <div v-else-if="!presaleAccess" class="row text-center">
				  	<label>You do not have access to presale, sorry!</label>
				  </div>
			  </div>
			  <div style="width:100%;float:left;padding-top:15px;padding-left:100px;padding-right:100px;" v-else-if="$store.state.contractError">
			  	<label class="error">Looks like there was trouble connecting your metamask account. Please make sure you have the correct network selected (rinkeby for now preston relax)</label>
			  </div>
			  <div style="width:100%;float:left;padding-top:15px;" v-else-if="!$store.state.currentAccount">
			  	<label class="error">Please connect your metamask account to view details about the contract...</label>
			  </div>
		</div>
		<div class="loadEverything" v-if="$store.state.loadingEverything">
			<b-spinner></b-spinner><br />
		</div>

		<!-- <div class="col-12 text-center" style="padding-top:25px;float:left;" v-if="checkOwner()">
  		<div style="width:100%;float:left;text-align:center;">
  			<button class="btn btn-sm btn-success" v-if="makeLive" @click="makeLive()">Make Live</button>
  		</div>
  		<div style="width:100%;float:left;text-align:center;margin-top:15px;margin-bottom:15px;">
  			<input type="text" style="width:300px;margin-left:auto;margin-right:auto;" placeholder="Address..." class="form-control" v-model="early_contract_address" />
  			<button class="btn btn-sm btn-success mt-2" v-if="makeLive" @click="addToEarlyAccess()">Add Address</button>
  		</div>
  	</div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import VueMetamask from "vue-metamask";
const BN = require('bn.js');
import {ethers} from "ethers";
import Web3 from "web3";
const axios = require('axios');
import contract from './../contracts/AYY_CEE.json'
const abi = contract.abi;
// const contractAddress = '0xB7E3Bc7013858C16D567cdC9b4fA7a26022F9D2e'
// const contractAddress = "0x32D5941C58b7AE8570A5D09aC555B03A9C487E20"
// const contractAddress = "0xC0EeA019bd5E76a7dA9438142cB6eB34b11fbD5e"
// const contractAddress = "0x8ceFB3e87390834cCDBDC598873C67C14DB90fEd"
// const contractAddress = "0x9860dCfdF796c5E9C347282096f23ecF076d14B1"
const contractAddress = "0x95E0B57b7B776DF013D5FFAA9B51b1496aB5644c"

var methods = {};

methods.configureNumber = async function (res) {
	this.purchased = 0
	this.total = 0
	var that = this
	res.data.forEach(function (val) {
		if (val.purchased == 1) {
			that.purchased++
		}
		that.total++
	})
	return 'success';
}

methods.goToOpensea = function () {
	window.open("https://testnets.opensea.io/collection/ayy-cee", "_blank");
}

methods.goToDiscord = function () {
	// window.open("https://discord/collection/amonroec-test", "_blank");
}

methods.connectWalletHandler = async function () {
	this.$store.dispatch('connectWallet')
}

methods.checkIsConnected = async function () {
	const {ethereum} = window;

	if (!ethereum) {
		return;
	} else {
	}

	var accounts = await ethereum.request({method: 'eth_accounts'})

	if (accounts.length !== 0) {
		this.currentAccount = accounts[0]
		// setCurrentAccount(this.currentAccount)
	} else {
	}
}

methods.checkPresale = async function () {
	if (this.$store.state.contractStatus) {
		const maxMint = await this.$store.state.contract.MAX_PER_MINT()
		this.maxMint = parseInt(maxMint.toString())
		this.presaleAccess = true
		return true
	} else {
		try {
			var addresses = await this.$store.state.contract.earlyAccess(this.$store.state.currentAccount)
			console.log('presale')
			console.log(addresses)
			if (addresses) {
				// const maxMint = await this.$store.contract.MAX_PER_MINT()
				this.maxMint = addresses
				this.presaleAccess = true
				return addresses
			} else {
				console.log('nope')
				this.presaleAccess = false
				return 0
			}
		} catch (e) {
			console.log(e)
		}
	}
}

methods.mintNftHandler = async function () {
	this.mintLoading = true
	try {
		const {ethereum} = window
		this.mintChanged()

		if (ethereum) {
			// const provider = new ethers.providers.Web3Provider(ethereum)
			// const signer = provider.getSigner();
			// const nftContract = new ethers.Contract(contractAddress, abi, signer);
			// let test = await nftContract.totalSupply()
			// let max = await nftContract.MAX_SUPPLY()
			var eth = parseFloat(.05 * parseInt(this.mint_number))
			let nftTxn = await this.$store.state.contract.mint(parseInt(this.mint_number), {value: ethers.utils.parseEther(eth.toString())})
			this.minting = true
			console.log(nftTxn)
			let wait = await nftTxn.wait()
			this.$store.dispatch('configureTotals', this.$store.state.contract).then(res => {
				this.minting = false
				this.mintLoading = false
			})
			// this.configureTotals().then(res => {
			// 	this.minting = false
			// 	this.mintLoading = false
			// })
		} else {
			this.mintLoading = false
		}
	} catch (err) {
		this.mintLoading = false
	}
}

methods.getTransactionParams = async function () {
	if (window.ethereum) {
		var obj = {
			nonce: '0x00',
			// gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
		  // gas: '0x2710', // customizable by user during MetaMask confirmation.
		  to: '0xbb1e83f7DeA0683a00c81160627a227e23f6Cfe6', // Required except during contract publications.
		  from: this.wallet.metaMaskAddress, // must match user's active address.
		  // value: '0x00', // Only required to send ether to the recipient from the initiating external account.
		  value: ethers.utils.parseEther('.002')._hex,
		  // data:
		  //   '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
		  chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
		}
		return obj
	} else {
		return null
	}
}

methods.makeLive = async function () {
	let makeLive = await this.contract.startLiveSale()
}

methods.requestMint = function () {
	this.mintChanged().then(res => {
		this.getTransactionParams().then(res => {
			if (this.wallet && res) {
				if (window.ethereum) {
					// this.wallet.web3.eth.
					const txHash = ethereum.request({
					  method: 'eth_sendTransaction',
					  params: [res],
					}).then(res => {
						const postData = {
							transaction_id: res,
							metaMaskAddress: this.wallet.metaMaskAddress,
							collection_id: this.collection_id,
							mint_number: this.mint_number
						}
						axios.post(this.$url + '/api/submitMint', postData).then(res => {
							if (res.data === 'success') {
							} else {
							}
						})
					}).catch(e => {
					});
				}
			}
		})
	})
}

methods.mintChanged = async function () {
	if (parseInt(this.mint_number) > this.maxMint) {
		this.mint_number = this.maxMint
	} else if (this.mint_number > (this.$store.state.maxSupply - this.$store.state.totalSupply)) {
		this.mint_number = this.$store.state.maxSupply - this.$store.state.totalSupply
	}
	return
}

methods.getData = function () {
	const postData = {
		collection_id: this.collection_id
	}
	axios.post(this.$url + '/api/getMintNumber', postData).then(res => {
		this.configureNumber(res).then(res => {
		});
	});
}

methods.getSigner = async function () {
	const {ethereum} = window
	try {
		this.provider = new ethers.providers.Web3Provider(ethereum)
		// this.wallet = new ethers.signer.getAddress(ethereum)
		// this.wallet = new this.provider.getAddress()
		return this.provider.getSigner();
	} catch (e) {
		this.loadingEverything = false
		this.contractError = true
	}
}

methods.configureTotals = async function () {
	try {
		const totalSupply = await this.contract.minted()
		const maxSupply = await this.contract.MAX_SUPPLY()
		const maxMint = await this.contract.MAX_PER_MINT()
		const contractStatus = await this.contract.live()
		const owner = await this.contract.owner()
		this.contractStatus = contractStatus
		this.owner = owner
		this.totalSupply = totalSupply.toString()
		this.maxSupply = maxSupply.toString()
		this.maxMint = parseInt(maxMint.toString())
		this.contractError = false
		return
	} catch (err) {
		this.contractError = true
		this.loadingEverything = false
	}
}

methods.getContract = async function () {
	try {
		this.contract = new ethers.Contract(contractAddress, abi, this.signer)
		// this.configureTotals()
		// this.contractError = false
		if (this.contract) {
			return 'success';
		} else {
			return 'error';
		}
	} catch {
		this.contractError = true
		this.loadingEverything = false
		return error
	}
	// this.totalSupply = await this.contract.totalSupply()
	// this.maxSupply = await nftContract.MAX_SUPPLY()
}

methods.loadEverything = async function () {
	this.loadingEverything = true
	this.getSigner().then(signer => {
		this.signer = signer
		// this.wallet = new this.signer.getAddress()
		this.getContract().then(contract => {
			if (!contract) {
				this.contractError = true
			} else {
				this.contractError = false
				this.configureTotals().then(res => {
					this.loadingEverything = false
				})
			}
		}).catch(e => {
		})
		// this.contract = contract
	})
}

methods.addToEarlyAccess = async function () {
	try {
		let nftTxn = await this.contract.setEarlyAccess([this.early_contract_address])
	} catch (e) {
	}
}

methods.checkOwner = function () {
	if (this.currentAccount && this.owner) {
		var current = ethers.utils.getAddress(this.currentAccount)
		var owner = ethers.utils.getAddress(this.owner)
		if ( current === owner) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}

export default {
  name: 'Home',
  data: function () {
  	return {
  		connectMeta: false,
  		connectLoading: false,
  		mintLoading: false,
  		minting: false,
  		wallet: null,
  		purchased: 0,
  		total: 0,
  		collection_id: 1,
  		collection: null,
  		mint_number: 1,
  		eth: null,
  		currentAccount: null,
  		contract: null,
  		provider: null,
  		signer: null,
  		totalSupply: null,
  		maxSupply: null,
  		maxMint: null,
  		contractError: false,
  		loadingEverything: true,
  		contractStatus: false,
  		owner: false,
  		presaleAccess: false,
  		early_contract_address: ''
  	}
  },
  components: {
    // VueMetamask
  },
  methods: methods,
  mounted: function () {
  	// this.getData()
  	// this.contract = await nftContract.totalSupply()
  	// this.loadEverything()
  	// this.checkIsConnected()
  	// var that = this
  	// setTimeout(function () {
	  // 		window.ethereum.on('accountsChanged', function (accounts) {
		 //      that.checkIsConnected()
		 //      that.loadEverything()
		 //    });

		 //     // detect Network account change
		 //    window.ethereum.on('networkChanged', function(networkId){
		 //      that.checkIsConnected()
		 //      that.loadEverything()
		 //    });
  	// }, 500)

  	// this.checkIsConnected()
  }
}
</script>
<style lang="scss" scoped>
#walletLoaded {
	font-size:25px;
	width:100%;
	float:left;
	text-align:center;
}
#contractStatus {
	font-size:40px;
	width:100%;
	float:left;
	text-align:center;
	color: #fff;
	font-weight:900;

	&.live {
		text-shadow: 2px 2px 6px green;
	}

	&.notLive {
		text-shadow: 2px 2px 6px red;
	}
}
#numToMint {

}
.connectMetaButton {
	background-image: linear-gradient(to right, #cf8332 , #e0e0e0);
	width:300px;

	&:hover {
		background-image: linear-gradient(to right, #e0e0e0, #cf8332);
		font-size:20px;
	}
}
#openSeaImage {
	&:hover {
		cursor: pointer;
		width:35px !important;
	}
}
#discordImage {
	margin-top:15px;
	width: 125px;
	&:hover {
		cursor: pointer;
		width:150px !important;
	}
}
.error{
	font-size:20px;
	font-weight: 800;
	text-shadow: 2px 2px 6px red;
}
#supply {
	font-size:30px;
}
.loadEverything {
	position:fixed;
	padding-top:15px;
	color:green;
	top:70px;
	left:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,.3);
}
</style>
