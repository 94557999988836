<template>
  <div id="app">
    <navbar></navbar>
    <div id="routerView">
      <router-view/>
    </div>
  </div>
</template>
<script>
import Navbar from './components/navbar'
var methods = {}

export default {
  name: 'App',
  components: {
    Navbar
  },
  data: function () {
    return {

    }
  },
  methods: methods,
  mounted: function () {
    this.$store.dispatch('checkWallet')
    this.$store.dispatch('loadEverything')
    this.$store.dispatch('checkIsConnected')
    var that = this
    setTimeout(function () {
      console.log(that.$store.state)
    }, 2000)
    // this.checkIsConnected()
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: linear-gradient(-45deg, #496da6, #BF0A30, #496da6, #e0e0e0);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  min-height:100vh;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
