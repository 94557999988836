<template>
  <div style="width:100%;float:left;">
    <div style="width:100%;float:left;" v-if="checkOwner()">
      <div class="col-12 text-center" style="padding-top:25px;float:left;">
        <div style="width:100%;float:left;text-align:center;">
          <button class="btn btn-sm btn-success" v-if="!$store.state.contractStatus" @click="makeLive()">Make Live</button>
          <button class="btn btn-sm btn-danger" v-else @click="makeNotLive()">Stop Live Sale</button>
        </div>
        <div style="width:100%;float:left;text-align:center;margin-top:15px;margin-bottom:15px;">
          <div style="width:100%;float:left;text-align:center;" v-for="(val, index) in early_contract_addresses" :key="index">
            <div style="width:400px;margin-left:auto;margin-right:auto;margin-top:10px;">
              <input type="text" style="width:300px;float:left;" placeholder="Address..." class="form-control" v-model="early_contract_addresses[index]" />
              <button class="btn btn-sm btn-danger" style="float:left;margin-left:5px;margin-top:3px;" @click="early_contract_addresses.splice(index, 1)">Remove</button>
            </div>
          </div>
          <button class="btn btn-sm btn-success mt-2" @click="early_contract_addresses.push('')">Add Address</button><br />
          <button class="btn btn-sm btn-info mt-2" v-if="!earlySubmitLoading" @click="addToEarlyAccess()">Submit Address(es)</button>
          <div style="width:100%;text-align:center;" v-else>
            <b-spinner></b-spinner>
          </div>
        </div>

        <hr style="width:200px;height:2px;background-color:#fff;margin-left:auto;margin-right:auto;" />
        <div style="width:100%;float:left;text-align:center;">
          <table class="table" style="width:300px;background-color:#fff;margin-left:auto;margin-right:auto;">
            <thead>
              <tr>
                <th>Address Added</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val, index) in early_accounts" :key="'table' + index">
                <td>{{val}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var methods = {}
import {ethers} from "ethers";
import Web3 from "web3";

methods.checkOwner = function () {
  if (this.$store.state.currentAccount && this.$store.state.owner) {
    var current = ethers.utils.getAddress(this.$store.state.currentAccount)
    var owner = ethers.utils.getAddress(this.$store.state.owner)
    console.log(current)
    console.log(owner)
    if ( current === owner) {
      return true
    } else {
      this.$router.push('/')
      return false
    }
  } else {
    this.$router.push('/')
    return false
  }
}

methods.addToEarlyAccess = async function () {
  this.earlySubmitLoading = true
  try {
    let nftTxn = await this.$store.state.contract.setEarlyAccess(this.early_contract_addresses)
    nftTxn.wait()
    this.earlySubmitLoading = false
  } catch (e) {
    this.earlySubmitLoading = false
    console.log(e)
  }
}

methods.getEarlyAccess = async function () {
  try {
    let early = await this.$store.state.contract.earlyAccess(this.$store.state.contract.address)
    console.log('early')
    console.log(early)
    // this.early_accounts = early
  } catch (e) {
    console.log(e)
  }
}

methods.makeLive = async function () {
  try {
    let makeLive = await this.$store.state.contract.startLiveSale()
    makeLive.wait()
    this.$store.dispatch('loadEverything')
  } catch (e) {
    console.log(e)
  }
}

methods.makeNotLive = async function () {
  try {
    let makeLive = await this.$store.state.contract.stopLiveSale()
    makeLive.wait()
    this.$store.dispatch('loadEverything')
  } catch (e) {
    console.log(e)
  }
}

methods.checkLive = async function () {
  try {
    let live = await this.$store.state.contract.live()
    console.log('check live')
    console.log(live)
    return live
  } catch (e) {
    console.log(e)
  }
}

export default {
  // ===Component name
  name: "OwnersPage",
  // ===Props passed to component
  props: {},
  // ===Components used by this component
  components: {

  },
  // ====component Data properties
  data(){
      return{
        early_contract_addresses: [''],
        early_accounts: ['testingaccounts'],
        earlySubmitLoading: false
      }
  },
  // ===Code to be executed when Component is mounted
  mounted() {
    this.getEarlyAccess()
  },
  // ===Computed properties for the component
  computed: {},
  // ===Component methods
  methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped></style>
